<template>
  <div class="col" v-loading="$store.getters['groups/load']">
    <el-row :gutter="20">
      <el-col
        :lg="8"
        v-for="group in groupsData"
        :key="group.id"
        class="text-right"
      >
        <el-card>
          <!-- course image  -->

          <img
            :class="{ 'd-none': group.course_image == null }"
            :lazy-src="courseimage(group.course_image)"
            :src="courseimage(group.course_image)"
            :alt="group.course_name"
            class="w-100"
          />
          <!-- <img src="" alt=""> -->

          <!-- course link  -->
          <router-link
            v-if="!hasRole('admin')"
            :to="`/course/${group.course_id}/${group.id}`"
            style="width: 100%"
          >
            <p class="h3">{{ group.name }}</p>

            <p class="mt-2 mr-3 h4">( {{ group.course_name }} )</p>
          </router-link>
          <!-- Teacher  -->
          <el-row>
            <el-col :span="18" class="text-right h4"
              ><router-link :to="`/user-profile/${group.teacher_id}`">
                <span>{{ group.teacher_name }}</span>
              </router-link></el-col
            >
            <!-- :class="{ 'd-none': group.course_image == null }" -->
            <el-col :span="6"
              ><img
                :src="image(group.teacher_id)"
                :lazy-src="image(group.teacher_id)"
                :alt="group.course_name"
                class="w-100"
              />
            </el-col>
          </el-row>
          <!-- dates  -->
          <el-row :gutter="10">
            <el-col :span="12">
              <p>
                {{ $t("to") }}
                <span>{{ toLocalDatetime(group.start_from) }}</span>
              </p>
            </el-col>
            <el-col :span="12">
              <p>
                {{ $t("from") }}
                <span>{{ toLocalDatetime(group.end_at) }}</span>
              </p>
            </el-col>
          </el-row>
          <!-- footer  -->
          <el-row :gutter="10">
            <el-col :span="12" class="text-left">
              <!-- @click="$router.push(`user-profile/${scope.row.id}`)" -->

              <el-button
                type="primary"
                v-if="group.pivot.status != 2"
                @click="$router.push(`/course/${group.course_id}/${group.id}/`)"
              >
                {{ $t("show_course") }}
              </el-button>
              <p v-else class="bg-danger">{{ $t("forbidden") }}</p>
            </el-col>
            <el-col :span="12">
              <el-badge
                type="primary"
                :value="day"
                v-for="day in group.days"
                :key="day.id"
                class="mb-3"
              >
              </el-badge>
              <el-rate v-model="4.5" disabled text-color="#ff9900"> </el-rate>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "index",

  mounted() {
    this.Refresh(null);
  },

  computed: {
    groupsData() {
      return this.$store.getters["groups/authGroups"];
    },
  },

  methods: {
    image(id) {
      const teacher = this.$store.getters["user/users"].filter(
        (itemInArray) => itemInArray.id == id
      )[0];
      if (teacher && teacher.image) {
        return `https://test.justtolearn.com/api/users/cover/${teacher.image}`;
      }
      return "avater.png";
    },
    courseimage(course) {
      if (course) {
        return `https://test.justtolearn.com/api/courses/cover/${course}`;
      }
      return "banner.jpg";
    },
    Refresh(query) {
      // this.$store.dispatch('courses/authCourses', {query: query});
      this.$store.dispatch("groups/authGroups", { query: query });
      this.$store.dispatch("user/users", { query: {} });
    },
  },
};
</script>

<style scoped>
/* rate style  */
::v-deep .el-rate__decimal.el-icon-star-on {
  transform: translateX(100%);
}

/* days badge style  */
::v-deep sup {
  top: 0;
}

::v-deep sup.el-badge__content {
  height: unset;
  padding: 5px 20px;
}
</style>
